import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import { ModalContext } from "../../context/ModalContext"
import { useState,useEffect } from 'react'
import { host, api_url } from "../../constant/url_api"
import axios from 'axios';
import TableTachesEnCours from '../Tables/TableTachesEnCours'
import ModalJsonArray from '../Modals/ModalJsonArray'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Taches72h from '../Charts/Taches72h'

function TachesEnCours(){
   
    const [show,setShow] = useState(false)
    const [typeModal,setTypeModal] = useState(null)
    const [listUpdate,setListUpdate] = useState(false)
    const [listeTache, setListeTache] = useState(null)
    const [id_modifer,setId_modifier] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [modalView, setModalView] = useState(null)
    const [taches72h, setTaches72h] = useState(null)
    const location = "tache"


     //initialisation
     useEffect(() => {
        axios
        .get(host() + api_url.tache.statistique72h)
        .then((res) => {
            setTaches72h(null)
            var result = res.data;
            if (result != null) {
             setTaches72h(result.tachesTraiteesMoins72h);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    const closeModal =()=>{

        setShow(false)
        setId_modifier(null)
        
    }

    const handleShowModal = (view) => {
        setModalView(view)
        setShow(true)
    }

    //initialisation
     useEffect(()=>{

            axios.get(host()+api_url.tache.encours)
            .then((res)=>{
                setListeTache([])
                var result = res.data
                if(result != null){
                   
                    result.forEach(element => {
                        setListeTache(current =>[...current,element])
                    });
                }else{
                    setListeTache([])
                }
            })
            .catch((err)=>{
                console.log(err)
            })

    },[])
    // onUpdate
    useEffect(()=>{
        if(listUpdate){

            axios.get(host()+api_url.tache.encours)
            .then((res)=>{
                setListeTache([])
                var result = res.data
                if(result != null){
                   
                    result.forEach(element => {
                        setListeTache(current =>[...current,element])
                    });
                }else{
                    setListeTache([])
                }
               
            })
            .catch((err)=>{
                console.log(err)
            })
            setListUpdate(false)
        }
    },[listUpdate])

    const handleCancelLaterTask =()=>{
        axios.get(`${host()}${api_url.tache.retard}`, {
            params: {
                type: 'manuel'
            }
        })
        .then((res) => {
            var result = res.data;
            console.log(result)
            if (result.statut == 1) {
                NotificationManager.success(result.msg, 'Succès', 2000);
                setListUpdate(true);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return(
        <>
         <ModalContext.Provider value={{
                show,setShow,
                typeModal,setTypeModal,
                closeModal,
                listUpdate,setListUpdate,
                listeTache, setListeTache,
                id_modifer,setId_modifier,
                location
           }}>
                <Header />
                <div className="d-flex flex-row">
                    <Sidebar />
                    <div className="container-fluid p-3 mt-3">
                        <div className="row p-2">
                            <div className="col-md-12">
                                <Taches72h  tasksData ={taches72h} />
                                <div className="row p-2">
                                    <div className="col-md-6">
                                        <h1 className="titre-page">Tâches en cours et programmées</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="alert alert-success" role="alert">
                                          <strong>A traiter:</strong> <a href={`${host()}/api/tache/a/traiter`} target="_blank">{`${host()}/api/tache/a/traiter`}</a>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="alert alert-warning" role="alert">
                                          <strong>Mise à jour: </strong><a href="#" target="_blank">{`${host()}/api/tache/retour/zenno?idtask=19&statut=echec`}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 d-flex flex-row align-items-center justify-content-end">
                                    <button className="btn btn-lg m-2 btn-danger"  onClick={() => handleCancelLaterTask()}>Annuler toutes les tâches en retard (echec)</button>
                                    <button className="btn btn-lg btn-rss m-2"  onClick={() => handleShowModal('array')}>Vue Tableau</button>
                                    <button className="btn btn-lg btn-rss m-2"  onClick={() => handleShowModal('json')}>Vue JSON</button>
                                </div>
                                {loading ? (
                                    <p>Chargement...</p>
                                ) : error ? (
                                    <p>{error}</p>
                                ) : (
                                    <TableTachesEnCours />
                                )}

                            </div>
                        </div>
                    </div>
                    <NotificationContainer />
                </div>
            </ModalContext.Provider>
            {show && <ModalJsonArray modalView={modalView} listeTache={listeTache} closeModal={closeModal} />}
        </>
    )
}

export default TachesEnCours