import {useEffect,useState,useContext,useRef} from 'react';
import DataTable from 'datatables.net-dt';
import ActionSite from '../Buttons/ActionSite'
import { ModalContext } from "../../context/ModalContext"
import { host, api_url } from "../../constant/url_api"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from "axios"

function TableSites(){

    const table = useRef()
    const bodyTable =useRef()

    const {listeSite,setShow,setTypeModal,setId_modifier,setListUpdate} = useContext(ModalContext)
    const [datasTable,setDatasTable] = useState([])
    const [currentTable,setCurrentTable] = useState()
    const [initTable, setInitTable] = useState(false)

    useEffect(()=>{

        if(datasTable.length > 0 ){
       
         setCurrentTable(
 
             new DataTable(table.current, {
                 destroy:true,
                 responsive: true,
                 searching: false,
                 dom: 'Bfrtlip',
                 pageLength: 50,
                 language: {
                     "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
                     "lengthMenu": "_MENU_",
                     "info": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                     "paginate": {
                         "previous": '<i class="bi bi-chevron-left"></i>',
                         "next": '<i class="bi bi-chevron-right"></i>'
                     }
                 },
                 data:datasTable,
                 columns:[
                 
                     {data:'nom_connexion'},
                     {data:'url_site'},
                     {data:'projet_connecter'},
                     {data:'nom_utilisateur'},
                     {data:'publication_realiser'},
                     {
                         data:null,
                         render:function(data,type,row){
                            return'<div class="dropdown">'
                                +'<p class="dropdown_projet dropdown-toggle"  id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">...</p>'
                                +'<ul class="dropdown-menu dropdown-menu-white text-center" aria-labelledby="dropdownMenuButton2">'
                                    +'<li><a class="dropdown-item modifier_site" href="#" data-id='+data.id+'>Modifier</a></li>'
                                    +'<li><hr class="dropdown-divider"/></li>'
                                    +'<li><a class="dropdown-item supprimer_site" href="#" data-id='+data.id+'>Supprimer</a></li>'
                                +'</ul>' 
                            +'</div>'
                         }
                     },
                 ],
                 drawCallback: function( settings ) {
 
                     var ajouter_site = document.getElementsByClassName("modifier_site");
                     for (var i = 0; i < ajouter_site.length; i++) {
                         ajouter_site[i].addEventListener('click',(e)=>{
                             e.preventDefault()
                             setShow(true)
                             setTypeModal('site')
                             setId_modifier(e.target.dataset.id)
                             
                         });
                     }
 
                     var supprimer_projet = document.getElementsByClassName("supprimer_site");
                     for (var i = 0; i < supprimer_projet.length; i++) {
 
                         supprimer_projet[i].addEventListener('click',(e)=>{
 
                            e.preventDefault()
                            const data = new FormData();
                            data.append('id_site',e.target.dataset.id)
                    
                            axios.post(host()+api_url.site.suppression,data)
                            .then((res)=>{
                                var result = res.data
                                if(result.statut == 1){
                                    NotificationManager.success(result.msg, 'Succés',2000)
                                    setListUpdate(true)
                                }else{
                                    NotificationManager.error(result.msg, 'Erreur',2000)
                                }
                            })
                            .catch((err)=>{
                                console.log(err)
                            })
                             
                         });
                     }
                 
                 }
                 
             })
            
         )
         setInitTable(true)
 
        }else{
            
             if(initTable){
                 currentTable.clear().draw()
             }
        }
 
 
     },[datasTable])

    useEffect(()=>{
  
        if(listeSite){
            setDatasTable([])
            if(listeSite.length > 0){
                listeSite.forEach(function(element,index){
                    setDatasTable(current=>[...current,element])
                })
            }
         
           
        }   
    },[listeSite])

    if(datasTable === null) return null 
    
    return (
            <>
            
               <table ref={table} className="table_site">
                    <thead>
                        <tr>
                            <th>Nom de connexion</th>
                            <th>URL site</th>
                            <th>Projet connecté</th>
                            <th>Nom utilisateur</th>
                            <th>Publication réalisé</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody ref={bodyTable}><tr><td colSpan={6}>Aucunes données disponibles</td></tr></tbody>
               </table>
            </>
    );

}
export default TableSites