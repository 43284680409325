
import { getConfig } from '../config';

export const host = () => {
  const configData = getConfig();
  return configData ? configData.API_URL : "http://default-url.com"; 
};

export const domain = () => {
  const configData = getConfig();
  return configData ? configData.DOMAIN_URL : "http://default-url.com"; 
};
//export const host = "https://taskm.yopyo.com/php/public"; // prod

export const api_url = {
  projet: {
    nouveau: "/api/nouveau-projet",
    liste: "/api/liste-projet",
    listeParTache: "/api/liste-projet-par-tache",
    get: "/api/get-projet/",
    suppression: "/api/supprimer-projet",
    pause: "/api/mettre-en-pause-projet",
    relancer: "/api/relancer-projet"
  },

  tache: {
    nouveau: "/api/nouveau-tache",
    liste: "/api/liste-tache",
    get: "/api/get-tache/",
    encours: "/api/tache/en/cours",
    avenir: "/api/tache/a/venir",
    terminer: "/api/tache/terminer",
    dernier_tache: "/api/dernier/tache/terminer",
    retard: "/api/tache/en/retard",
    suppression: "/api/supprimer-tache",
    statistique: "/api/statistique-tache",
    statistique72h: "/api/statistique-tache-72h",
    updateZenno: "/api/tache/retour/zenno",

  },
  configtache: {
    nouveau: "/api/nouveau-config-tache",
    liste: "/api/liste-config-tache",
    get: "/api/get-config-tache/",
    suppression: "/api/supprimer-config-tache",
  },
  configthematique: {
    nouveau: "/api/nouveau-config-thematique",
    liste: "/api/liste-config-thematique",
    get: "/api/get-config-thematique/",
    suppression: "/api/supprimer-config-thematique",
  },

  chart: {
    projet: "/api/taches-par-projet/",
    type_tache_projet: "/api/get-taches-par-type-et-projet/",
    statistique_tache_projet:'/api/statistique-taches/par/projet/'
  },
};
