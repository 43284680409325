import {useContext, useEffect, useState, useRef} from 'react';
import DataTable from 'datatables.net-dt';
import { ModalContext } from "../../context/ModalContext";
import { host, api_url } from "../../constant/url_api";
import axios from "axios";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useNavigate } from 'react-router-dom';


function TableProjetsTache() {

    const table = useRef();
    const bodyTable = useRef();

    const {setShow, setTypeModal, setIdProjet,idProjet, listeProjet, loadTable, setLoadTable, setUpdateProjet} = useContext(ModalContext);
    const [datasTable, setDatasTable] = useState([]);
    const [currentTable, setCurrentTable] = useState();
    const [initTable, setInitTable] = useState(false);
    const [listeTache, setListeTache] = useState(null);
    const [taches72h, setTaches72h] = useState(null)
    const navigate = useNavigate();

      //initialisation
      useEffect(() => {
        axios
        .get(host() + api_url.tache.statistique)
        .then((res) => {
            setTaches72h(null)
            var result = res.data;
            if (result != null) {
             setTaches72h(result.tachesTraiteesMoins72h);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    //initialisation
    useEffect(() => {
        axios.get(host() + api_url.configtache.liste)
            .then((res) => {
                var result = res.data;
                setListeTache(result ? result : []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (listeProjet) {
            const transformedData = listeProjet.map(projet => {
                let tachesData = {};
                projet.taches.forEach(tache => {
                    tachesData[tache.nom_type_tache] = {
                        action_jour: tache.action_jour,
                        creneau_horaire: tache.creneau_horaire
                    };
                });
                return {
                    id: projet.projet.id,
                    nom_projet: projet.projet.nom_projet,
                    statut:projet.projet.statut,
                    total_taches: projet.total_taches,
                    ...tachesData
                };
            });
            setDatasTable(transformedData);
        }
    }, [listeProjet]);

    useEffect(() => {
        if (datasTable.length > 0 && Array.isArray(listeTache)) {
            
            const columns = [
                {data: 'id'},
                {
                    data: null,
                    render: function(data, type, row) {
                        return (data.statut != 0 
                                ?'<a href="#" class="project-link projet-'+data.id+'">' + data.nom_projet + '</a>'
                                :'<a href="#" class="project-link projet-'+data.id+'">' + data.nom_projet + '</a> <br> <span style="color:red!important">(EN PAUSE)<span>')
                    }
                },
                {
                    data: null,
                    render: function(data, type, row) {
                        return '<p>'+data.total_taches+'<br><a href="#" class="tache_new" data-id=' + data.id + '>Créer nouvelle tâche</a></p>';
                    }
                },
                ...listeTache.map(tache => ({
                    data: tache.nom_type_tache + '.action_jour',
                    title: tache.nom_type_tache,
                    render: function(data, type, row) {
                        return data ? parseFloat(data) : 'N/A';
                    }
                })),
                {
                    data: null,
                    render: function(data, type, row) {
            
                        return '<div class="dropdown">'
                            + '<p class="dropdown_projet dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">...</p>'
                            + '<ul class="dropdown-menu dropdown-menu-white text-center" aria-labelledby="dropdownMenuButton2">'
                            + '<li><a class="dropdown-item modifier_projet" href="#" data-id=' + data.id + '>Modifier</a></li>'
                            + '<li><hr class="dropdown-divider"/></li>'
                            + '<li><a class="dropdown-item supprimer_projet" href="#" data-id=' + data.id + '>Supprimer</a></li>'
                            + '<li><hr class="dropdown-divider"/></li>'
                            + (data.statut != 0 
                                ? '<li><a class="dropdown-item pause_projet" href="#" data-id="' + data.id + '" data-button ="pause" ">Mettre en Pause </a></li>' 
                                : '<li><a class="dropdown-item pause_projet" href="#" data-id="' + data.id + '" data-button ="relance">Relancer Projet</a></li>')
                            + '</ul>'
                            + '</div>';
                    }
                }
            ];

            setCurrentTable(
                new DataTable(table.current, {
                    destroy: true,
                    responsive: true,
                    searching: true,
                    dom: 'Bfrtlip',
                    pageLength: 50,
                    language: {
                        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
                        "lengthMenu": "_MENU_",
                        "info": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                        "paginate": {
                            "previous": '<i class="bi bi-chevron-left"></i>',
                            "next": '<i class="bi bi-chevron-right"></i>'
                        }
                    },
                    data: datasTable,
                    columns: columns,
                    drawCallback: function(settings) {

                        var projectLinks = document.getElementsByClassName("project-link");
                        for (var i = 0; i < projectLinks.length; i++) {
                            projectLinks[i].addEventListener('click', (e) => {
                                e.preventDefault();
                                const projectName = e.target.textContent;
                                navigate(`/taches?project=${encodeURIComponent(projectName)}`);
                            });
                        }

                        var nouvelle_tache = document.getElementsByClassName("tache_new");

                        for (var i = 0; i < nouvelle_tache.length; i++) {
                            nouvelle_tache[i].addEventListener('click', (e) => {
                                e.preventDefault();
                                setShow(true);
                                setTypeModal('tachePage');
                                setIdProjet(e.target.dataset.id);
                            });
                        }

                       

                        var modifier_site = document.getElementsByClassName("modifier_projet");
                        for (var i = 0; i < modifier_site.length; i++) {
                            modifier_site[i].addEventListener('click', (e) => {
                                e.preventDefault();
                                setShow(true);
                                setTypeModal('projet');
                                setIdProjet(e.target.dataset.id);
                            });
                        }

                        var supprimer_projet = document.getElementsByClassName("supprimer_projet");
                        for (var i = 0; i < supprimer_projet.length; i++) {
                            supprimer_projet[i].addEventListener('click', (e) => {
                                e.preventDefault();
                                setLoadTable(false);
                                const data = new FormData();
                                data.append('id_projet', e.target.dataset.id);

                                axios.post(host() + api_url.projet.suppression, data)
                                    .then((res) => {
                                        var result = res.data;
                                        if (result.statut == 1) {
                                            NotificationManager.success(result.msg, 'Succés', 2000);
                                            this.
                                            setLoadTable(true);
                                            setUpdateProjet(true);
                                        } else {
                                            setLoadTable(true);
                                            NotificationManager.error(result.msg, 'Erreur', 2000);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            });
                        }

                        var pause_projet = document.getElementsByClassName("pause_projet");

                        for (var i = 0; i < supprimer_projet.length; i++) {

                            pause_projet[i].addEventListener('click', (e) => {
                                e.preventDefault();
                                setLoadTable(false);
                                const data = new FormData();
                                data.append('id_projet', e.target.dataset.id);
                                var type_bouton = e.target.dataset.button
                                var url_api = (type_bouton == 'pause') ? api_url.projet.pause : api_url.projet.relancer
                                axios.post(host() + url_api, data)
                                    .then((res) => {
                                        var result = res.data;
                                        if (result.statut == 1) {
                                            NotificationManager.success(result.msg, 'Succés', 2000);
                                            setLoadTable(true);
                                            setUpdateProjet(true);
                                        } else {
                                            setLoadTable(true);
                                            NotificationManager.error(result.msg, 'Erreur', 2000);
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            });
                        }


                    
                    }
                })
            );
            setInitTable(true);
        } else {
            if (initTable) {
                currentTable.clear().draw();
            }
        }
    }, [datasTable,listeTache]);

    const typeTache = () => {
        if (listeTache !== null) {
            return listeTache.map((tache) => (
                <th key={tache.id}>{tache.nom_type_tache}</th>
            ));
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="spinner-border" role="status" style={{display: (loadTable) ? "none" : "block"}}>
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <table className="table_projet" ref={table} style={{display: (loadTable) ? "block" : "none"}}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Projets</th>
                        <th>Tâches</th>
                        {typeTache()}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody ref={bodyTable}><tr><td colSpan={9}>Aucune données disponible</td></tr></tbody>
            </table>
            <NotificationContainer/>
        </>
    );
}

export default TableProjetsTache;
