let config = null;

export function loadConfig() {
  // Retourne une promesse qui sera résolue lorsque la configuration est chargée
  return fetch("/config.json")
    .then(response => response.json())
    .then(data => {
      config = data;
    })
    .catch(error => {
      console.error("Erreur lors du chargement de la configuration :", error);
      config = {}; // Si la configuration échoue, définis un objet vide
    });
}

export function getConfig() {
  return config;
}

export const host = () => {
  const configData = getConfig();
  return configData.API_URL // Valeur par défaut si la config est manquante
};
