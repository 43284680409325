import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Connexion from "./components/Pages/Connexion";
import Projets from "./components/Pages/Projets";
import DetailsProjet from "./components/Pages/DetailsProjet";
import Sites from "./components/Pages/Sites";
import Taches from "./components/Pages/Taches";
import ConfigTache from "./components/Pages/ConfigTache";
import ConfigThematique from "./components/Pages/ConfigThematique";
import NotFound from "./components/Pages/NotFound";
import ProjetsTache from "./components/Pages/ProjetsTache";
import TachesEnCours from "./components/Pages/TachesEnCours";
import TachesAVenir from "./components/Pages/TachesAVenir";
import TachesTerminer from "./components/Pages/TachesTerminer";
import ApiExplorer from "./components/Pages/ApiExplorer";
import Documentation from "./components/Pages/DocumentationTaskM";

function App() {
  return (
    <>
      <Routes forceRefresh>
        <Route path="*" element={<NotFound />} />
        <Route exact path="/projet" element={<Projets />} />
        <Route exact path="/details-projets/:id" element={<DetailsProjet />} />
        <Route exact path="/sites" element={<Sites />} />
        <Route exact path="/" element={<ProjetsTache />} />
        <Route exact path="/taches" element={<Taches />} />
        <Route exact path="/tache-en-cours" element={<TachesEnCours />} />
        <Route exact path="/tache-a-venir" element={<TachesAVenir />} />
        <Route exact path="/tache-terminer" element={<TachesTerminer />} />
        <Route exact path="/configuration-tache" element={<ConfigTache />} />
        <Route
          exact
          path="/configuration-thematique"
          element={<ConfigThematique />}
        />
         <Route exact path="/api-endpoints" element={<ApiExplorer />} />
         <Route exact path="/documentation-taskm" element={<Documentation />} />
      </Routes>
    </>
  );
}

export default App;
