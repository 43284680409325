import React from "react";
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import { domain} from "../../constant/url_api"
import "bootstrap/dist/css/bootstrap.min.css";

const DocumentationTaskM = () => {
  return (
    <>
      <Header />
      <div className="d-flex flex-row">
        <Sidebar />
        <div className="container-fluid p-3 mt-3">
          <h1 className="titre-page">Documentation installation</h1>
          <div className="m-5" id="documentation">
          <h2 className="text-decoration-underline text-success">Prérequis</h2>
 
            <ul>
                <li>PHP (version 8.1 ou supérieure)</li>
                <li>Composer (pour gérer les dépendances)</li>
                <li>Nginx ou Apache (serveur web)</li>
                <li>Base de données (MySQL)</li>
            </ul>

            <h2 className="text-decoration-underline text-success">Installation de l’API (LARAVEL)</h2>
    
            <h3 className="pt-2">Étape 1 : Téléchargement et Décompression des fichiers</h3>
              <p>Télécharger le fichier <a href={domain()+'api.zip'} target="_blank">ici (<strong>api.zip</strong>)</a>, puis décompresser le zip et placer tous les éléments du zip dans un dossier <strong>api</strong> sur votre serveur.</p>
    
            <h3>Étape 2 : Modifier le fichier <code>.env</code></h3>
            <p>Une fois le fichier zip décompressé, configurez le fichier <code>.env</code> :</p>
            <kbd>
                APP_ENV=production<br/>
                APP_DEBUG=false <br/>
                APP_URL=http://votre-site.com <br/>
                DB_CONNECTION=mysql <br/>
                DB_HOST=127.0.0.1 <br/>
                DB_PORT=3306 <br/>
                DB_DATABASE=nom_de_votre_base <br/>
                DB_USERNAME=utilisateur  <br/>
                DB_PASSWORD=mot_de_passe <br/>
            </kbd>

            <h3 className="mt-4">Étape 3 : Installation des dépendances (composer) et Base de données</h3>
            <p>Ouvrez un terminal via SSH ou sur le serveur si disponible, placez-vous dans le dossier <strong>api</strong> et exécutez les commandes suivantes un par un :</p>
            <p>
              <kbd>
                composer install
              </kbd>
            </p>
            <p>
              <kbd>
                  php artisan migrate
              </kbd>
            </p>
            <h2 className="text-decoration-underline text-success mt-4 mb-4">Installation du FrontEnd (REACTJS)</h2>
    
            <h3>Étape 1 : Téléchargement et Décompression des fichiers</h3>
            <p>Téléchargez le fichier <a href={domain()+'front.zip'} target="_blank">ici (<strong>front.zip</strong>)</a>, puis décompressez-le et placez les éléments du zip à la racine de votre domaine.</p>
    
            <h3>Étape 2 : Modification du fichier <code>config.json</code></h3>
            <p>Modifier la variable d’environnement suivante par l'url de l'api qui vient d'être installer:</p>
            <pre>
              API_URL="https://votre-nom-de-domaine/nom_du_dossier_api/public"
            </pre>

            <h2 className="text-decoration-underline text-success mt-4 mb-4">Configuration de la tâche CRON</h2>
            <p> Aller dans votre page de configuration de cron sur votre serveur.</p>
            <p> Ensuite configurer les taches cron suivant selon votre besoin (toutes les minutes etc...) :</p>
            <p>Programme les tâches a traité aléatoirement:</p>
            <p><kbd>php a_remplacer_par_le_chemin_vers_la_racine_api/php/artisan programmation:tache</kbd></p>
            <p>Mets à jour automatiquement à non traiter (NT) les tâches en retard:</p>
            <p><kbd>php a_remplacer_par_le_chemin_vers_la_racine_api/php/artisan programmation:retard</kbd></p>
            <p>Reintégre les tâches qui n'ont pas été traiter:</p>
            <p><kbd>php a_remplacer_par_le_chemin_vers_la_racine_api/php/artisan programmation:reintégrer</kbd></p>
            <p><strong>C'est fini ! Vous pouvez utiliser l'outil. Bonne utilisation !</strong></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentationTaskM;
