import { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line } from "react-chartjs-2"

// Enregistrer les composants nécessaires pour un Line chart
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default function Taches72h({
  tasksData,
  title = "Suivi des tâches  des dernières 72 heures",
  height = "400px",
  width = "100%",
  hoursToShow = 72, // Nombre d'heures à afficher
}) {
  // État pour stocker les données traitées
  const [chartData, setChartData] = useState(null)

  // Traiter les données lorsque tasksData change
  useEffect(() => {
    if (!tasksData) return

    try {
      const tachesTraiteesMoins72h = typeof tasksData === "string" ? JSON.parse(tasksData) : tasksData

      // Créer un tableau des dernières heures
      const lastHours = []
      for (let i = 0; i < hoursToShow; i++) {
        const date = new Date()
        date.setHours(date.getHours() - (hoursToShow - 1 - i), 0, 0, 0)
        lastHours.push(date)
      }

      // Formater les dates pour les labels (format: JJ/MM HH:00)
      const labels = lastHours.map(
        (date) =>
          `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")} ${date.getHours().toString().padStart(2, "0")}:00`,
      )

      // Initialiser les compteurs pour succès et échecs
      const successCounts = Array(hoursToShow).fill(0)
      const failureCounts = Array(hoursToShow).fill(0)

      // Compter les tâches par heure
      tachesTraiteesMoins72h.forEach((tache) => {
        const updatedAt = new Date(tache.updated_at)

        // Trouver l'index de l'heure correspondante
        const hourIndex = lastHours.findIndex(
          (date) =>
            date.getFullYear() === updatedAt.getFullYear() &&
            date.getMonth() === updatedAt.getMonth() &&
            date.getDate() === updatedAt.getDate() &&
            date.getHours() === updatedAt.getHours(),
        )

        if (hourIndex !== -1) {
          // statut === 1 signifie succès, sinon échec
          if (tache.statut === 1) {
            successCounts[hourIndex]++
          } else if (tache.statut === 0) {
            failureCounts[hourIndex]++
          }
        }
      })

      // Mettre à jour les données du graphique
      setChartData({
        labels,
        datasets: [
          {
            label: "Succès",
            data: successCounts,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            tension: 0.4,
          },
          {
            label: "Échecs",
            data: failureCounts,
            borderColor: "rgba(255, 99, 132, 1)",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            tension: 0.4,
          },
        ],
      })
    } catch (error) {
      console.error("Erreur lors du traitement des données:", error)
    }
  }, [tasksData, hoursToShow])

  // Options du graphique
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
        font: {
          size: 16,
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => tooltipItems[0].label,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Nombre de tâches",
        },
      },
      x: {
        title: {
          display: true,
          text: "Heure",
        },
        ticks: {
          // Afficher moins de labels pour éviter l'encombrement
          maxRotation: 45,
          minRotation: 45,
          callback: function (value, index, values) {
            // Afficher seulement quelques labels pour éviter l'encombrement
            return index % 6 === 0 ? this.getLabelForValue(value) : ""
          },
        },
      },
    },
  }

  // Afficher un message de chargement si les données ne sont pas encore prêtes
  if (!chartData) {
    return <div>Chargement du graphique...</div>
  }

  return (
    <div style={{ height, width }}>
      <Line options={options} data={chartData} />
    </div>
  )
}

