import { useContext, useEffect, useState } from "react"
import { ModalContext } from "../../context/ModalContext"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { host, api_url } from "../../constant/url_api"
import axios from "axios"

function ModalNouveauThematique(){

    const {show,closeModal,setListUpdate,id_modifer,setId_modifier} = useContext(ModalContext)
    const [thematique_modifier,setThematique_modifier] =useState(null)
    const [value_thematique,setValue_thematique]= useState('')
    
    const submitSite = (e)=>{

        e.preventDefault()
        const form = document.querySelector('#nouveauthematiqueform');
        const data = new FormData(form);
        axios.post(host()+api_url.configthematique.nouveau,data)
        .then((res)=>{

            var result = res.data
            if(result.statut == 1){
                NotificationManager.success(result.msg, 'Succés',2000)
                setListUpdate(true)
                setId_modifier(null)
                setThematique_modifier(null)
                setValue_thematique('')
                closeModal()
            }else{
                NotificationManager.error(result.msg, 'Erreur',2000)
                closeModal()
            }
        })
        .catch((err)=>{
            console.log(err)
        })

    }


    useEffect(()=>{
        
        if(id_modifer != null){
            axios.get(host()+api_url.configthematique.get+id_modifer)
            .then((res)=>{
                setThematique_modifier(null)
                var result = res.data
                if(result != null){
          
                    setThematique_modifier(result)
                    setValue_thematique(result.nom_thematique)
                }
               
            })
            .catch((err)=>{
                console.log(err)
            })
        }else{
            setThematique_modifier(null)
            setValue_thematique('')
        }
    },[id_modifer])


    const ChangeNomThematique =(e)=>{
        e.preventDefault()
        setValue_thematique(e.target.value)
        
    }

    
    return(
        <>
            <div className="modal fadeshow" id="modalnouveauthematique" style={{display: (show) ? "block" : "none"}}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="exampleModalLabel">{(id_modifer)? "Modifier site #"+id_modifer : "Créer une thématique"} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={submitSite} id="nouveauthematiqueform">
                        <div className="modal-body text-left">
                            <div className="form-group d-flex flex-row align-items-center justify-content-around mt-3">
                                <label className="w-25"><strong>Nom thématique: </strong></label>
                                <input type="text" className="form-control w-75" id="nom_thematique" name="nom_thematique" placeholder="Thematique" autoComplete="on" onChange={ChangeNomThematique} value={value_thematique} required/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <input type="hidden" className="form-control" name="id_modifier" value={(id_modifer)? id_modifer :""} />
                            <button type="submit" className="btn btn-rss" data-dismiss="modal">Enregistrer</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
            <div className={(show) ? "modal-backdrop fade show" : "modal-backdrop fade"} style={{display: (show) ? "block" : "none"}}></div>
            <NotificationContainer />
        </>
    )
}

export default ModalNouveauThematique