import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { host, api_url } from "../../constant/url_api";
import axios from "axios";

// Enregistrer les composants nécessaires pour un Line chart
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function TachesParProjet({ nomProjet }) {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!nomProjet) return;
    axios
    .get(`${host()}${api_url.chart.statistique_tache_projet}`, {
        params: {
            nomProjet: nomProjet
        }
    })
    .then((res) => {
        console.log('ato at ddd',res)
        processChartData(res.data);
    })
    .catch((err) => {
        console.log(err);
    });

  }, [nomProjet]);

  const processChartData = (tasksData) => {
    if (!tasksData || tasksData.length === 0) {
      setChartData(null);
      setLoading(false);
      return;
    }

    // Extraire les noms des types de tâches
    const typesTaches = [...new Set(tasksData.map((t) => t.nom_type_tache))];

    // Générer les labels (Types de tâches)
    const labels = typesTaches;

    // Générer les datasets pour succès et échecs
    const successCounts = typesTaches.map((type) => {
      const tache = tasksData.find((t) => t.nom_type_tache === type);
      return tache ? tache.succes : 0;
    });

    const failureCounts = typesTaches.map((type) => {
      const tache = tasksData.find((t) => t.nom_type_tache === type);
      return tache ? tache.echecs : 0;
    });

    setChartData({
      labels,
      datasets: [
        {
          label: "Succès",
          data: successCounts,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          tension: 0.4,
        },
        {
          label: "Échecs",
          data: failureCounts,
          borderColor: "rgba(255, 99, 132, 1)",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          tension: 0.4,
        },
      ],
    });

    setLoading(false);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: `Suivi des tâches pour ${nomProjet}`, font: {size: 16} },
    },
    scales: {
      y: { beginAtZero: true, title: { display: true, text: "Nombre de tâches" } },
      x: { title: { display: true, text: "Type de Tâche" } },
    },
  };

  return (
    <div style={{ height: "400px", width: "100%" }}>
      {loading ? <div>Chargement du graphique...</div> : chartData ? <Line options={options} data={chartData} /> : <div>Aucune donnée disponible</div>}
    </div>
  );
}
