import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'react-notifications/lib/notifications.css';


import "@popperjs/core"
import "bootstrap";

import './index.css'
import App from './App'

import { loadConfig } from './config';



// Charger la configuration avant de démarrer React
loadConfig()
  .then(() => {
    // Une fois la config chargée, démarrer React
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
  })
  .catch(error => {
    console.error("Erreur lors de la configuration, React ne démarre pas :", error);
  });




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
