import {useContext, useEffect, useState,useRef,useMemo} from 'react';
import DataTable from 'datatables.net-dt';
import { ModalContext } from "../../context/ModalContext"
import AjoutSite from '../Buttons/AjoutSite';
import ActionProjet from '../Buttons/ActionProjet'
import { host, api_url } from "../../constant/url_api"
import axios from "axios"
import {NotificationContainer, NotificationManager} from 'react-notifications';


function TableProjets(){

    const table = useRef()
    const bodyTable =useRef()

    const {setShow,setTypeModal,setIdProjet,listeProjet,loadTable,setLoadTable,setUpdateProjet} = useContext(ModalContext)
    const [datasTable,setDatasTable] = useState([])
    const [currentTable,setCurrentTable] = useState()
    const [initTable, setInitTable] = useState(false)
   
    useEffect(()=>{

       if(datasTable.length > 0 ){
      
        setCurrentTable(

            new DataTable(table.current, {
                destroy:true,
                responsive: true,
                searching: false,
                dom: 'Bfrtlip',
                pageLength: 50,
                language: {
                    "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
                    "lengthMenu": "_MENU_",
                    "info": "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
                    "paginate": {
                        "previous": '<i class="bi bi-chevron-left"></i>',
                        "next": '<i class="bi bi-chevron-right"></i>'
                    }
                },
                data:datasTable,
                columns:[
                
                    {
                            data:null,
                            render:function(data,type,row){
                                return'<a href="/details-projets/'+data.id+'">'+data.nom+'</a>'

                            }
                    },
                    {data:'flux'},
                    {data:'filtres'},
                    {data:'url_valider'},
                    {data:'redaction'},
                    {data:'publier'},
                    {
                        data:null,
                        render:function(data,type,row){
                            if(data.site_connecter == 0){
                                return '<strong>Non</strong><br><a href="#" class="ajouter_site"  data-id='+data.id+'>Ajouter</a>'
                            }else{
                                return '<strong>Oui</strong><br><a href="#" class="modifier_site" data-id='+data.id+'>Modifier</a>'
                            }
                        }
                    },
                    {
                        data:null,
                        render:function(data,type,row){
                            if(data.categorie_site == null){
                                return ''
                            }else{
                                return data.categorie_site
                            }
                        }
                    },
                    {
                        data:null,
                        render:function(data,type,row){
                            return'<div class="dropdown">'
                                            +'<p class="dropdown_projet dropdown-toggle"  id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">...</p>'
                                            +'<ul class="dropdown-menu dropdown-menu-white text-center" aria-labelledby="dropdownMenuButton2">'
                                                +'<li><a class="dropdown-item supprimer_projet" href="#" data-id='+data.id+'>Supprimer</a></li>'
                                            +'</ul>' 
                                      +'</div>'
                        }
                    },
                ],
                drawCallback: function( settings ) {

                    var ajouter_site = document.getElementsByClassName("ajouter_site");
                    for (var i = 0; i < ajouter_site.length; i++) {
                        ajouter_site[i].addEventListener('click',(e)=>{
                            e.preventDefault()
                            setShow(true)
                            setTypeModal('add_site')
                            setIdProjet(e.target.dataset.id)
                            
                        });
                    }

                    var modifier_site = document.getElementsByClassName("modifier_site");
                    for (var i = 0; i < modifier_site.length; i++) {
                        modifier_site[i].addEventListener('click',(e)=>{
                            e.preventDefault()
                            setShow(true)
                            setTypeModal('add_site')
                            setIdProjet(e.target.dataset.id)
                            
                        });
                    }

                    var supprimer_projet = document.getElementsByClassName("supprimer_projet");
                    for (var i = 0; i < supprimer_projet.length; i++) {

                        supprimer_projet[i].addEventListener('click',(e)=>{

                            e.preventDefault()
                            setLoadTable(false)
                            const data = new FormData();
                            data.append('id_projet',e.target.dataset.id)
                    
                            axios.post(host()+api_url.projet.suppression,data)
                            .then((res)=>{
                                var result = res.data
                                if(result.statut == 1){
                                    NotificationManager.success(result.msg, 'Succés',2000)
                                    setLoadTable(true)
                                    setUpdateProjet(true)
                                }else{
                                    setLoadTable(true)
                                    NotificationManager.error(result.msg, 'Erreur',2000)
                                }
                            })
                            .catch((err)=>{
                                console.log(err)
                            })
                            
                        });
                    }
                
                }
                
            })
           
        )
        setInitTable(true)

       }else{
           
            if(initTable){
                currentTable.clear().draw()
            }
       }


    },[datasTable])
   
    useEffect(()=>{
         
        if(listeProjet){
            
            setDatasTable([])
          
            if(listeProjet.length > 0){
             
                listeProjet.forEach(function(element,index){
                    setDatasTable(current=>[...current,element])
                })
             
         
            }

            
        }   
    },[listeProjet])
  

    return (
            <>
              <div className="row justify-content-center">
                    <div className="spinner-border" role="status" style={{display: (loadTable) ? "none" : "block"}}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
               </div>
               <table className="table_projet" ref={table} style={{display: (loadTable) ? "block" : "none"}}>
                    <thead>
                        <tr>
                            <th>Nom du projet</th>
                            <th>Nombre de flux</th>
                            <th>Filtres recherche</th>
                            <th>Urls validé</th>
                            <th>Nombre rédaction</th>
                            <th>Nombre publié</th>
                            <th>Site connecté</th>
                            <th>Catégorie site</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody ref={bodyTable}><tr><td colSpan={9}>Aucune données disponible</td></tr></tbody>
                   </table>
                   <NotificationContainer/>
            </>
    )



}
export default TableProjets