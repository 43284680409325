import { useEffect, useState, useContext, useRef } from "react";
import DataTable from "datatables.net-dt";
import ActionSite from "../Buttons/ActionSite";
import { ModalContext } from "../../context/ModalContext";
import { host, api_url } from "../../constant/url_api";
import Taches72h from "../../components/Charts/Taches72h";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";

function TableTaches() {
  const table = useRef();
  const bodyTable = useRef();

  const { listeTache, setShow, setTypeModal, setId_modifier, setListUpdate } =
    useContext(ModalContext);
  const [datasTable, setDatasTable] = useState([]);
  const [currentTable, setCurrentTable] = useState();
  const [initTable, setInitTable] = useState(false);

  useEffect(() => {
    if (datasTable.length > 0) {
      setCurrentTable(
        new DataTable(table.current, {
          destroy: true,
          responsive: true,
          searching: true,
          dom: "Bfrtlip",
          pageLength: 50,
          language: {
            url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
            lengthMenu: "_MENU_",
            info: "Affichage de _START_ à _END_ sur _TOTAL_ entrées",
            paginate: {
              previous: '<i class="bi bi-chevron-left"></i>',
              next: '<i class="bi bi-chevron-right"></i>',
            },
          },
          data: datasTable,
          order: [[4, "desc"]],
          columns: [
            { data: "id_tache" },
            { data: "tache" },
            { data: "projet" },
            {
              data: null,
              render: function (data, type, row) {
                let raison = data.raison ? "(" + data.raison + ")" : " ";
                if (data.statut == 1) {
                  return '<p><a href="#" class="tache_ok">OK</a></p>';
                } else {
                  return (
                    '<p class="tache_echec">Echec <br> ' + raison + " </p>"
                  );
                }
              },
            },
            { data: "date_execution_zenno" },
            { data: "date_demande" },
          ],
          initComplete: function () {
            this.api()
              .columns()
              .every(function (index) {
                if (index !== 5) {
                  // Skip checkbox and execution columns
                  var column = this;
                  const input = document.getElementById(
                    `filter-input-${index}`
                  );
                  if (input) {
                    input.onkeyup = function () {
                      column.search(this.value).draw();
                    };
                    column.header().appendChild(input);
                  }
                }
              });
          },
        })
      );
      setInitTable(true);
    } else {
      if (initTable) {
        currentTable.clear().draw();
      }
    }
  }, [datasTable]);

  useEffect(() => {
    if (listeTache) {
      setDatasTable([]);
      if (listeTache.length > 0) {
        listeTache.forEach(function (element, index) {
          setDatasTable((current) => [...current, element]);
        });
      }
    }
  }, [listeTache]);

  const filterInputs = [
    <input
      id="filter-input-0"
      key="0"
      style={{ width: "100%" }}
      placeholder="Filtre ID..."
      className="form-control"
    />,
    <input
      id="filter-input-1"
      key="1"
      style={{ width: "100%" }}
      placeholder="Filtre Tâches..."
      className="form-control"
    />,
    <input
      id="filter-input-2"
      key="2"
      style={{ width: "100%" }}
      placeholder="Filtre Projet..."
      className="form-control"
    />,
    <input
      id="filter-input-3"
      key="3"
      style={{ width: "100%" }}
      placeholder="Filtre Statut..."
      className="form-control"
    />,
    <input
      id="filter-input-4"
      key="4"
      style={{ width: "100%" }}
      placeholder="Filtre Date..."
      className="form-control"
    />,
  ];

  if (datasTable === null) return null;

  return (
    <>
      <div className="row p-2">
        <div className="col-md-12">
          <div className="d-flex justify-content-between">
            {filterInputs.map((input) => (
              <div key={input.key}>{input}</div>
            ))}
          </div>
        </div>
      </div>
      <table ref={table} className="table_site">
        <thead>
          <tr>
            <th>ID</th>
            <th>Tâches</th>
            <th>Projets</th>
            <th>Statuts</th>
            <th>Exécution ZENNO</th>
            <th>Dates demande</th>
          </tr>
        </thead>
        <tbody ref={bodyTable}>
          <tr>
            <td colSpan={6}>Aucunes données disponibles</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
export default TableTaches;
