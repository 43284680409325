import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { host, api_url } from "../../constant/url_api";

// Enregistrement des éléments nécessaires
ChartJS.register(ArcElement, Tooltip, Legend);

const ProjetDonutsChart = () => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const projectFilter = query.get("project"); // Récupération du filtre de projet depuis l'URL

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
      },
    ],
  });

  const [projectDetails, setProjectDetails] = useState({
    nom_projet: "",
    taches_en_cours: 0,
    taches_futures: 0,
    taches_terminees: 0,
  });

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        if (projectFilter) {
          const response = await axios.get(
            host() + api_url.chart.projet + projectFilter
          );
          const data = response.data;

          setProjectDetails({
            nom_projet: data.nom_projet,
            taches_en_cours: data.taches_en_cours,
            taches_futures: data.taches_futures,
            taches_terminees: data.taches_terminees,
          });

          const formattedData = formatChartData(data);
          setChartData(formattedData);
        } else {
          // Gestion du cas où projectFilter est absent
          //   setError("Aucun projet sélectionné.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
        setError("Erreur lors de la récupération des données.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectFilter]);

  const formatChartData = (data) => {
       
      const datasetLabel = `Tâches pour ${data.nom_projet}`;

      const labels = [
        `Tâches en cours (${data.taches_en_cours})`,
        `Tâches futures (${data.taches_futures})`,
        `Tâches terminées (${data.taches_terminees})`,
      ];

      return {
        labels: labels,
        datasets: [
          {
            label: datasetLabel,
            data: [
              data.taches_en_cours,
              data.taches_futures,
              data.taches_terminees,
            ],
            backgroundColor: ["#FFCE56", "#FF6384", "#1fcf47"],
          },
        ],
      };
  };

  if (loading) return <p>Chargement...</p>;
  if (error) return <p>{error}</p>;

  // Vérif projectFilter si valide avant de rendre le chart
  if (!projectFilter) {
    return <></>;
  }

  return (
    <div className="chart-container">
      <h4>Projet : {projectDetails.nom_projet}</h4>
      <Doughnut data={chartData} />
    </div>
  );
};

export default ProjetDonutsChart;
